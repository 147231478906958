@font-face {
  font-family: "GintoBold";
  src: url("./fonts/ABCGintoNormal-Bold.woff2") format("woff2"), url("./fonts/ABCGintoNormal-Bold.woff") format("woff");
}

@font-face {
  font-family: "GintoLight";
  src: url("./fonts/ABCGintoNormal-Light.woff2") format("woff2"), url("./fonts/ABCGintoNormal-Light.woff") format("woff");
}

@media (max-width:480px)  { 
  .App-logo {
    margin-top: -250px !important;
    margin-bottom: 0px !important;
    text-align: center;
  }

  .down-arrow {
    margin-top: -120px !important;
  }

  .faq-right-segment {
    width: 100% !important;
    padding: 0px 10px 10px 10px !important;
    margin-top: -50px;
    z-index: 100;
  }

  .faq-left-segment {
    display: none;
  }

  .footer-nieuwsbrief-form-block {
    margin-top: 20px;
    margin-left: -20px;
    margin-bottom: 20px;
  }

  .faq-list-title {
    display: block !important;
  }

  .footer-info-link {
    margin-right: 0px !important;
  }

  .logo-border {
    border-left: 0px !important;
    border-top: 3px solid #8068e4 !important;
    width: 250px;
    height: 0px !important;
  }
}

.container-fluid {
  margin: 0;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  margin-top: 150px;
  margin-bottom: 150px;
  text-align: center;
}

.App-background {
  background-image: url('./images/landing_background.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  background-image: url('./images/landing_background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Newsletter-block {
  margin: 50px 20px 20px 20px;
  background-color: #8068e4;
  border-radius: 30px;
  font-family: 'GintoLight', sans-serif;
}

.Newsletter-text {
  padding: 25px 25px 0px 25px;
  font-size: 16px;
  color: white;
}

.Newsletter-input-box {
  padding: 0px 0px 25px 25px;  
}

.Newsletter-input {
  margin: 20px 0px 0px 0px;
  padding-left: 20px;
  height: 50px;
  width: 250px;
  border: 0px;
  background-color: #4b418d;
  border-radius: 30px;
  color: #8b84b5;
}

.Newsletter-success-message {
  margin: 20px 0px 0px 0px;
  padding: 10px;
  height: 50px;
  width: 250px;
  border: 0px;
  background-color: white;
  border-radius: 30px;
  color: #4b418d;
}

.Newsletter-input::placeholder {
  color: #8b84b5;
  opacity: 1;
}

.Newsletter-button {
  margin-left: -40px;
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 50%;
  border: 0;
}

.down-arrow {
  position: absolute;
  top: calc(100vh - 60px);
  left: calc(50% - 14px);
  width: 0;
  height: 30px;
  border: white 1px solid;
  border-radius: 2px;
  color: white;
  animation: jumpInfinite 2.5s infinite;
}

.down-arrow:after {
  content: " ";
  position: absolute;
  top: 12px;
  left: -10px;
  width: 16px;
  height: 16px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  border-radius: 2px;
  margin-left: 2px;
  transform: rotateZ(45deg);
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}

.blue-gradient-down {
  z-index: 5;
  background-image: linear-gradient(to bottom, rgba(143, 211, 234, 0), #8fd3ea);
  height: 405px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.blue-gradient-up {
  z-index: 5;
  background-image: linear-gradient(to bottom, #8fd3ea, rgba(143, 211, 234, 0));
  height: 405px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.text-container {
  z-index: 10;
  width: 100%;
  max-width: 727px;
  position: relative;
}

.body-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 130px;
  padding-bottom: 81px;
  display: flex;
  position: relative;
}

.heading {
  margin-bottom: 75px;
  padding-left: 45px;
  padding-right: 45px;
  font-family: 'GintoBold', sans-serif;
}

b, strong {
  font-family: 'GintoBold', sans-serif;
  font-weight: 700;
}

p {
  color: #000;
  margin-bottom: 0;
  font-family: 'GintoLight', sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 26px;
}

ul {
  color: #000;
  margin-bottom: 0;
  font-family: 'GintoLight', sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 26px;
  margin-left: 25px;
}

h1 {
  color: #000;
  margin-top: 0 !important;
  margin-bottom: 10px !important;
  font-family: Ginto, sans-serif !important;
  font-size: 50px;
  font-weight: 700 !important;
  line-height: 47px !important;
}

.page-title {
  color: #000 !important;
  margin-top: 0 !important;
  margin-bottom: 10px !important;
  font-family: 'GintoBold', sans-serif !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 27px !important;
}

h3 {
  color: #000;
  margin-top: 0;
  margin-bottom: 10px;
  font-family: 'GintoBold', sans-serif;
  font-size: 27px;
  font-weight: 300;
  line-height: 32px;
}

h4 {
  color: #000 !important;
  font-family: 'GintoBold', sans-serif !important;
}

.article p {
  margin-bottom: 10px;
  padding-left: 45px;
  padding-right: 45px;
}

.article ul {
  margin-bottom: 10px;
  padding-left: 45px;
  padding-right: 45px;
}

.article h1 {
  padding-left: 45px;
  padding-right: 45px;
  font-size: 20px;
}

.photos-block {
  z-index: 10;
  width: 100%;
  position: relative;
  margin-top: 50px;
}

.photos-item {
  width: 100%;
  padding-bottom: 100%;
  height: 300px;
  border-radius: 30px;
  padding: 5px 5px 0px 0px;
  margin-bottom: 20px;
}

.photos-item-1 {
  background-image: url('./images/foto1.jpg');
  background-size: cover;
  background-position: center;
}

.photos-item-2 {
  background-image: url('./images/foto2.jpg');
  background-size: cover;
  background-position: center;
}

.photos-item-3 {
  background-image: url('./images/foto6.jpeg');
  background-size: cover;
  background-position: 60% 40%;
}

.photos-item-4 {
  background-image: url('./images/foto5.jpeg');
  background-size: cover;
  background-position: center;
}

/**
* FAQ
*/

.faq-container {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 0;
  display: flex;
}

.faq-left-segment {
  width: 43%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.faq-right-segment {
  justify-content: center;
  align-items: flex-start;
  width: 57%;
  padding: 0px 60px 60px 80px;
  display: flex;
}

.faq-left-block {
  background-image: linear-gradient(to bottom, rgba(143, 211, 234, 0) 50%, #191a36), url(./images/foto3.jpg);
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  padding-bottom: 70px;
  padding-left: 90px;
  padding-right: 90px;
  display: flex;
  position: static;
  top: 0;
}

.faq-right-block {
  background-color: #fff;
  border-radius: 30px;
  width: 100%;
  max-width: 680px;
  padding: 20px 40px;
}

.faq-container {
  background-color: #8FD3EA;
}

.faq-left-wrapper {
  max-width: 425px;
  position: static;
  top: auto;
  bottom: 250px;
  left: 90px;
  right: auto;
}

.faq-left-title {
  color: #fff;
  letter-spacing: .19px;
  text-transform: none;
  margin-bottom: 30px;
  font-size: 75px;
  line-height: 42px;
}

.faq-gradient {
  background-image: linear-gradient(to bottom, #191a36 30%, rgba(25, 26, 54, 0));
  height: 10vh;
}

.faq-text {
  color: #fff;
  width: 100%;
  margin-bottom: 30px;
}

.faq-link { 
  color: white !important; 
}

.faq-list-item {
  padding-bottom: 20px;
}

/**
* footer
*/

.footer-container {
  background-color: #8FD3EA;
}

.footer-block {
  background-color: #8FD3EA;
  padding: 45px 30px 30px 30px;
}

.footer-wrapper {
  background-color: #000;
  border-radius: 50px;
  height: auto;
  padding: 50px;
}

.footer-top {
  margin-bottom: 60px;
}

.footer-brand-wrapper {
  float: left;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 45px;
  display: flex;
}

.footer-nieuwsbrief-wrapper {
  float: left;
}

.footer-links-wrapper {
  float: right;
  display: flex;
}

.footer-bot {
  position: static;
}

.footer-brand-img {
  width: 180px;
  display: block;
}

.footer-nieuwsbrief-form-block {
  width: 280px;
}

.newsletter-title {
  font-family: 'GintoLight', sans-serif;
  color: white;
}

.newsletter-footer-input {
  margin: 5px 0px 0px 0px;
  padding-left: 20px;
  height: 50px;
  width: 250px;
  border: 0px;
  background-color: #4b418d;
  border-radius: 30px;
  color: #fff;
}

.Newsletter-footer-input-box {
  padding: 0px 0px 0px 0px;
}

.Newsletter-footer-success-message {
  margin: 5px 0px 0px 0px;
  padding: 10px;
  height: 50px;
  width: 250px;
  border: 0px;
  background-color: white;
  border-radius: 30px;
  color: #4b418d;
}

.footer-links-wrap {
  flex-direction: column;
  align-items: flex-start;
  margin-left: 35px;
  display: flex;
}

.footer-link {
  color: white;
  font-size: 19px;
  font-weight: 300;
  line-height: 30px;
  text-decoration: none;
  transition: color .2s;
  font-family: 'GintoLight', sans-serif;
}

.footer-link:hover {
  color: #8FD3EA;
}

.footer-info-link {
  float: left;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, .3);
  margin-right: 151px;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  text-decoration: none;
  transition: border-color .2s;
  font-family: 'GintoLight', sans-serif;
}

.newsletter-footer-block {
  background-color: #8068e4;
  border-radius: 20px;
  padding: 10px;
}

.w-clearfix:before, .w-clearfix:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-clearfix:after {
  clear: both;
}

.faq-list-title {
  display: none;
  font-family: 'GintoBold', sans-serif;
}

.logo-text-wrapper {
  overflow: hidden;
}

.logo-border {
  border-left: 3px solid #8068e4;
  height: 75px;
  float: left;
}

.logo-footer-text {
  color: white;
  font-family: "GintoLight";
  font-size: 25px;
  float: left;
  padding: 15px 0px 10px 20px;
}

.ang-footer-logo {
  height: 75px;
  float: right;
}